<template>
    <collapsible-items-section
        class="faqs-section-module"
        :title="attributes.faqSectionTitle"
        :intro="attributes.intro"
        :items="items"
        :open-text="$snippet('view_faqs', 'view faqs')"
    />
</template>

<script>
    import CollapsibleItemsSection from '../../../organisms/CollapsibleItemsSection/CollapsibleItemsSection';

    export default {
        name: 'faqs-section-module',

        components: { CollapsibleItemsSection },

        props: {
            attributes: {
                type: Object,
                default: () => ({}),
            },
        },

        computed: {
            /**
             * Format FAQ items.
             *
             * @returns {Array}
             */
            items() {
                return (this.attributes.faqs?.data || []).map((item) => {
                    return {
                        title: item.attributes.title,
                        content: item.attributes.content,
                        centered: item.attributes.centered,
                    };
                });
            },
        },
    };
</script>
