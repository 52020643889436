<template>
    <fade-up-animation :element="() => $refs.section">
        <section class="collapsible-items-section" ref="section">
            <b-container>
                <mobile-gutter>
                    <div class="wrapper">
                        <b-button
                            v-b-toggle="parentCollapseId"
                            variant="link"
                            class="toggle-button"
                            block
                        >
                            <div class="header">
                                <h2 class="title" v-test:title>
                                    {{ title }}
                                </h2>
                                <b-row>
                                    <b-col
                                        v-if="intro"
                                        md="8"
                                        offset-md="2"
                                    >
                                        <markdown-content
                                            :content="intro"
                                        />
                                    </b-col>
                                </b-row>
                                <div class="more-text" aria-hidden="true">
                                    <span class="more-title">
                                        <span class="when-open" v-test:closeText>
                                            {{ closeText }}
                                        </span>
                                        <span class="when-closed" v-test:openText>
                                            {{ openText }}
                                        </span>
                                    </span>
                                    <span class="more-icon-container">
                                        <app-icon
                                            name="plus"
                                            alt="Toggle content"
                                        />
                                    </span>
                                </div>
                            </div>
                        </b-button>
                        <b-collapse :id="parentCollapseId">
                            <b-row>
                                <b-col xl="10" offset-xl="1">
                                    <div class="items">
                                        <info-collapse
                                            v-for="item in collapseItems"
                                            :key="item.title"
                                            :title="item.title"
                                            :id="item.id"
                                            :centered="item.centered"
                                        >
                                            <markdown-content
                                                :content="item.content"
                                                v-test:infoCollapseContent
                                            />
                                        </info-collapse>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-collapse>
                    </div>
                </mobile-gutter>
            </b-container>
        </section>
    </fade-up-animation>
</template>

<script>
    import InfoCollapse from '../../molecules/InfoCollapse/InfoCollapse';
    import MarkdownContent from '../../utils/MarkdownContent/MarkdownContent';
    import uniqueId from 'lodash/uniqueId';
    import { BCollapse, VBToggle } from 'bootstrap-vue';

    export default {
        name: 'collapsible-items-section',

        components: { InfoCollapse, MarkdownContent, BCollapse },

        directives: {
            'b-toggle': VBToggle,
        },

        props: {
            title: {
                type: String,
                required: true,
            },

            intro: {
                type: String,
                default: '',
            },

            items: {
                type: Array,
                required: true,
            },

            closeText: {
                type: String,
                default: 'close',
            },

            openText: {
                type: String,
                default: 'open',
            },
        },

        computed: {
            /**
             * Add an id to the parent collapse.
             *
             * @returns {string}
             */
            parentCollapseId() {
                return uniqueId();
            },

            /**
             * Add an id to the collapse items data.
             *
             * @returns {object[]}
             */
            collapseItems() {
                return this.items.map((item) => ({
                    ...item,
                    id: `collapse-${ uniqueId() }`,
                }));
            },
        },
    };
</script>

<style lang="scss" scoped>
    .collapsible-items-section {
        padding: 34px 0;
    }

    .wrapper {
        border: 1px solid secondary-palette(7);
        padding: 20px 20px 0;

        &:focus-within {
            border-color: secondary-palette(6);
        }
    }

    .toggle-button {
        border-radius: 0;
        color: inherit;
        font-weight: inherit;

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
            color: inherit;
        }

        &.collapsed .when-open,
        &.not-collapsed .when-closed {
            display: none;
        }
    }

    .header {
        text-align: center;
        margin-bottom: 11px;
    }

    .title {
        font-size: rem(32px);
        line-height: rem(36px);
        font-weight: 700;
        @include media-breakpoint-up(lg) {
            font-size: rem(40px);
            line-height: rem(44px);
        }
    }

    .intro {
        margin-top: 11px;
    }

    .more-text {
        margin-bottom: 10px;
        background-color: secondary-palette(7);
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 7px 5px 10px;
        border-radius: 50px;
        min-width: 100px;

        &:hover,
        &:focus-visible,
        &:active {
            outline: 1px solid $itsu-primary;
        }
    }

    .more-title {
        font-size: rem(12px);
        font-weight: 400;
        padding-right: 7px;
        position: relative;
        top: 1px;
    }

    .more-icon-container {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .app-icon {
        transition: transform 0.3s ease;

        .not-collapsed & {
            @include transitions-enabled {
                transform: rotate(45deg);
            }
        }
    }

    .info-collapse {
        margin-bottom: 15px;
    }
</style>
