<template>
    <div
        class="info-collapse"
        :class="{ centered, coloured }"
    >
        <b-button
            variant="link"
            class="toggle-button"
            v-b-toggle="`collapse-${ id }`"
        >
            <span
                class="title"
                v-test:title
            >{{ title }}</span>

            <app-icon
                name="plus"
                alt="Toggle content."
            />
        </b-button>

        <b-collapse :id="`collapse-${ id }`">
            <div class="content">
                <slot />
            </div>
        </b-collapse>
    </div>
</template>

<script>
    import { BCollapse, VBToggle } from 'bootstrap-vue';

    export default {
        name: 'info-collapse',

        components: { BCollapse },

        directives: {
            'b-toggle': VBToggle,
        },

        props: {
            id: {
                type: String,
                required: true,
            },

            title: {
                type: String,
                required: true,
            },

            centered: {
                type: Boolean,
                default: false,
            },

            coloured: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .info-collapse {
        border: 1px solid secondary-palette(7);
    }

    .toggle-button {
        text-decoration: none;
        text-transform: none;
        font-size: rem(16px);
        line-height: rem(28px);
        width: 100%;
        text-align: left;
        font-weight: 300;
        padding: 15px 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: secondary-palette(4);
        border-radius: 0;
        position: relative;

        &:hover,
        &:focus-visible,
        &:active {
            border-color: secondary-palette(5);
        }

        &:focus {
            box-shadow: none;
        }
    }

    .title {
        transition: transform 0.2s ease;
    }

    .app-icon {
        margin-left: 30px;
        flex-shrink: 0;
        width: 15px;
        height: 15px;
        color: $itsu-primary;
        transition: transform 0.3s ease;

        .not-collapsed & {
            @include transitions-enabled {
                transform: rotate(45deg);
            }
        }
    }

    .content {
        padding: 20px 25px;
        font-size: rem(14px);
        font-weight: 300;

        ::v-deep p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .coloured {
        &.info-collapse {
            border-left: 0;
            border-right: 0;
            border-color: secondary-palette(6);
        }

        .toggle-button {
            color: $itsu-primary;
            font-size: rem(24px);
            font-weight: 700;
            justify-content: flex-start;

            &.not-collapsed {
                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: -2px;
                    left: -1px;
                    right: -1px;
                    border-top: 2px solid primary-palette(2);
                }
            }
        }

        .app-icon {
            width: 32px;
            height: 32px;
            padding: 10px;
            border-radius: 50%;
            background-color: secondary-palette(8);
            @include media-breakpoint-up(lg) {
                background-color: secondary-palette(7);
            }
        }
    }

    .centered {
        .toggle-button {
            text-align: center;
            justify-content: center;
            font-weight: 700;
            border-left: 0;
            border-right: 0;
        }

        &:not(.coloured) {
            .toggle-button {
                padding-left: 70px;
                padding-right: 70px;
            }

            .app-icon {
                position: absolute;
                right: 25px;
            }
        }

        &.coloured .toggle-button {
            justify-content: center;
        }
    }
</style>
